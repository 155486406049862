import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import adminRoutes from './admin.route'
import authRoutes from './auth.route'
import mainRoutes from './main.route'
import odrAuth from './odrauth.route'
import odrUserRoutes from './odruser.route'
import userRoutes from './user.route'

Vue.use(VueRouter)

const routes = [
  mainRoutes,
  ...adminRoutes,
  authRoutes,
  userRoutes,
  odrAuth,
  odrUserRoutes,
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, _from) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | Legal Naija'
  }
})

router.beforeEach((to, _from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/getToken'] === '') {
      next({
        path: '/signin',
        query: { redirect: to.fullPath },
        params: { redirect: to.fullPath }
      })
    } else [next()]
  } else {
    next()
  }
  next()
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (localStorage.getItem('jwt') == null) {
//       next({
//         path: '/login',
//         params: { nextUrl: to.fullPath }
//       })
//     } else {
//       const user = JSON.parse(localStorage.getItem('user'))
//       if (to.matched.some(record => record.meta.is_admin)) {
//         if (user.is_admin === 1) {
//           next()
//         } else {
//           next({ name: 'userboard' })
//         }
//       } else {
//         next()
//       }
//     }
//   } else if (to.matched.some(record => record.meta.guest)) {
//     if (localStorage.getItem('jwt') == null) {
//       next()
//     } else {
//       next({ name: 'userboard' })
//     }
//   } else {
//     next()
//   }
// })

export default router
