import odrAuthLayout from '../layouts/odrauth.vue'

const odrAuth = {
  path: '/odr',
  component: odrAuthLayout,
  children: [
    {
      name: 'odr-signin',
      path: '/odr/signin',
      component: () => import('../pages/odr/signin.vue')
    },
    {
      name: 'odr-signup',
      path: '/odr/signup',
      component: () => import('../pages/odr/signup.vue')
    }
  ]
}

export default odrAuth
