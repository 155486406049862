const notificationModule = {
  state: () => ({
    title: '',
    message: '',
    showNotification: '',
    type: ''
  }),
  mutations: {
    SET_NOTIFICATION(state, { title, message, showNotification, type }) {
      state.token = title
      state.message = message
      state.showNotification = showNotification
      state.type = type
    }
  },
  actions: {
    updateNotification({ commit }, { title, message, type }) {
      commit('SET_NOTIFICATION', {
        title,
        message,
        showNotification: true,
        type
      })
      setTimeout(() => {
        commit('SET_NOTIFICATION', {
          title: '',
          message: '',
          showNotification: false
        })
      }, 5000)
    }
  },
  getters: {}
}

export default { namespaced: true, ...notificationModule }
