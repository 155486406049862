<template>
  <div
    :class="`flex h-screen bg-gray-50 dark:bg-gray-900 ${
      isSideMenuOpen ? 'overflow-hidden' : ''
    } `"
  >
    <Desktop />
    <Mobile v-if="sidebar" class="md:hidden" />
    <div class="flex flex-col flex-1">
      <Header />
      <main class="h-full pb-16 overflow-y-auto">
        <div class="container p-6 mx-auto grid">
          <transition
            enter-class="opacity-10"
            enter-active-class="transition ease-in duration-150"
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
            leave-active-class="transition ease-out duration-150"
          >
            <router-view></router-view>
          </transition>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Desktop from '../components/sidebars/desktop.vue'
import Mobile from '../components/sidebars/mobile.vue'
import Header from '../components/sidebars/header.vue'
export default {
  components: { Desktop, Header, Mobile },
  middleware: 'authenticated',
  data() {
    return {
      isSideMenuOpen: false,
    }
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.ui.sidebar,
    }),
  },
  methods: {
    toggleSideMenu() {
      this.isSideMenuOpen = !this.isSideMenuOpen
    },
  },
}
</script>

<style>
#components-layout-demo-fixed-sider .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>
