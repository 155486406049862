<template>
  <li class="relative px-6 py-3">
    <!-- <span
      v-if="onRoute"
      class="absolute inset-y-0 left-0 w-2 bg-primary"
      aria-hidden="true"
    ></span> -->
    <router-link
      :to="to"
      :class="` ${
        onRoute ? 'text-opacity-100 bg-primary-dark py-3 px-2 rounded' : ''
      } text-white py-3 px-2 rounded text-opacity-60 inline-flex items-center w-full text-sm transition-colors duration-150 ${
        onRoute
          ? 'hover:text-white'
          : 'hover:text-opacity-100 hover:bg-primary-dark  hover:rounded hover:text-white'
      }`"
    >
      <slot name="icon"></slot>
      <span class="ml-4">{{ title }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    title: { type: String, required: true },
    to: { type: String, required: true },
  },
  computed: {
    onRoute() {
      return this.$route.path === this.to
    },
  },
  mounted() {},
}
</script>
