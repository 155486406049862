<template>
  <div class="background_auth h-screen overflow-y-scroll">
    <Navbar />
    <div class="flex h-full pt-32 items-center justify-center w-full p-5">
      <div class="text-center md:w-4/12 pb-4">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/navbar.vue'
export default {
  components: { Navbar },
}
</script>

<style>
.background_auth {
  background-image: url('../assets/images/image.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
