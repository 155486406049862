<template>
  <div id="footer" class="bg-white">
    <div class="w-10/12 mx-auto container py-2 px-3">
      <div class="md:flex mb-3 w-full py-4">
        <div class="md:w-3/12 mb-7 mb:mb-0">
          <img class="w-20" src="../assets/Legal-Naija-Logo.jpeg" />
        </div>
        <div class="flex items-center justify-start md:w-3/12 mb-7 mb:mb-0">
          <img src="../assets/images/aoc.png" class="w-24" alt="aoc" />
          <h4 class="pl-2 mb-0 text-base font-bold">Legal Partner</h4>
        </div>
        <div class="md:w-6/12 md:px-4 mb-7 mb:mb-0">
          <div class="flex w-full items-center md:justify-end justify-start">
            <span class="bg-primary-dark rounded-full p-3 mr-2">
              <img src="../assets/svg/facebook.svg" class="w-7 h-7" alt="" />
            </span>
            <span class="bg-primary-dark rounded-full p-3 mx-2">
              <img src="../assets/svg/twitter.svg" class="w-7 h-7" alt="" />
            </span>
            <span class="bg-primary-dark rounded-full p-3 mx-2">
              <img src="../assets/svg/youtube.svg" class="w-7 h-7" alt="" />
            </span>
            <span class="bg-primary-dark rounded-full p-3 ml-2">
              <img src="../assets/svg/linkedin.svg" class="w-7 h-7" alt="" />
            </span>
          </div>
        </div>
      </div>
      <div class="md:flex py-2">
        <div class="md:w-3/12 w-full mr-1.5 mb-7 mb:mb-0">
          <h3
            :style="{ fontSize: '22px' }"
            class="text-xl text-primary md:mb-7 mb-4 font-bold"
          >
            Office
          </h3>
          <p class="font-semibold text-base md:w-8/12 text-gray-500">
            18b, Esomo Close, Off Toyin Street, Ikeja, Lagos
          </p>
        </div>
        <div class="md:w-3/12 w-full mb-7 mb:mb-0">
          <h3
            :style="{ fontSize: '22px' }"
            class="text-xl text-primary md:mb-7 mb-4 font-bold"
          >
            Contact Us
          </h3>
          <div
            class="flex flex-col md:space-y-1 space-y-2 font-semibold text-base"
          >
            <a
              href="mailto:hello@legalnaija.com"
              class="text-primary-dark hover:text-primary-dark hover:underline"
              >hello@legalnaija.com</a
            >
            <a
              href="+2349029755663"
              class="text-gray-500 hover:text-gray-500 hover:underline"
              >+2349029755663</a
            >
            <a
              href="+2347088695103"
              class="text-gray-500 hover:text-gray-500 hover:underline"
              >+2347088695103</a
            >
          </div>
        </div>
        <div class="md:w-6/12 md:px-4 mb-7 mb:mb-0">
          <h3
            :style="{ fontSize: '22px' }"
            class="text-xl text-primary md:mb-7 mb-4 font-bold"
          >
            Subscribe For Updates &amp; Free Resources
          </h3>
          <div>
            <form class="mb-4">
              <input
                placeholder="Email"
                class="w-full px-3 py-3 border border-gray-300 focus:outline-none text-base placeholder-gray-500 mb-3 rounded"
              />
              <button
                class="bg-primary-dark text-white uppercase py-4 font-semibold text-center w-full rounded block"
                @click.prevent="() => {}"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-primary-dark">
      <div
        class="w-10/12 mx-auto container text-white font-semibold py-5 text-sm px-3"
      >
        <div
          class="flex md:flex-row flex-col-reverse items-center justify-between"
        >
          <p class="m-0 mb- text-sm">© 2021 Legal Naija. All Rights Reserved</p>
          <div class="flex mb-4 md:mb-0 text-xl">
            <span>
              <img
                class="w-8 mr-2 h-8"
                src="../assets/svg/facebook.svg"
                alt=""
              />
            </span>
            <span>
              <img
                class="w-8 mx-2 h-8"
                src="../assets/svg/twitter.svg"
                alt=""
              />
            </span>
            <span>
              <img
                class="w-8 ml-2 h-8"
                src="../assets/svg/instagram.svg"
                alt=""
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
