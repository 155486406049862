<template>
  <div class="py-4 mt-3 text-white">
    <span
      v-if="loggedInUser === 'admin' || loggedInUser !== 'super-admin'"
      class="text-3xl font-bold text-white text-center block hover:text-white"
    >
      Legal Naija
    </span>

    <div
      v-if="loggedInUser !== 'admin'"
      class="flex items-center justify-center flex-col"
    >
      <img
        v-if="!!!user.avatar"
        src="../../assets/svg/user_avatar.svg"
        class="w-32 h-32"
        alt="no_image"
      />
      <img
        v-else
        :src="user.avatar"
        class="rounded-full w-32 h-32"
        :alt="user.firstName"
      />

      <h3 class="mt-3 text-white font-semibold text-2xl">
        {{
          loggedInUser === 'admin' || loggedInUser === 'super-admin'
            ? 'Admin'
            : user.firstName
        }}
      </h3>
    </div>

    <ul class="mt-4">
      <sidebar-item
        v-for="route in menus"
        :key="route.title"
        :to="route.link"
        :title="route.title"
      >
        <template #icon>
          <a-icon class="text-xl" :type="route.icon" />
        </template>
      </sidebar-item>
    </ul>
    <div class="px-6 my-6 mt-10 bg-red-400 text-white">
      <button
        class="
          flex
          items-center
          justify-between
          w-full
          px-4
          py-2
          text-sm
          font-medium
          leading-5
          text-white
          transition-colors
          duration-150
        "
        @click="logout"
      >
        Logout
        <a-icon type="logout" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import sidebarItem from './sidebar-item.vue'
import { adminRoutes, lawyerRoutes, userRoutes } from '../../utils/routes'
import { logout } from '../../utils/helpers'

export default {
  name: 'SidebarContent',
  components: { sidebarItem },
  data() {
    return {
      lawyerRoutes,
      loggedInUser: '',
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    menus() {
      const presentRoute = this.$route.fullPath
      if (presentRoute.includes('admin')) return adminRoutes
      if (presentRoute.includes('lawyer')) return lawyerRoutes
      if (presentRoute.includes('odr')) return userRoutes
      return lawyerRoutes
    },
  },
  created() {
    this.loggedInUser = this.$store.getters['auth/getLoggedInUserType']
  },
  methods: {
    logout() {
      logout({ $store: this.$store, $router: this.$router })
    },
  },
}
</script>

<style>
#desktop-sidebar::-webkit-scrollbar {
  width: 5px;
  border-radius: 15px;
}

#desktop-sidebar::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: #3d8854;
}

#desktop-sidebar::-webkit-scrollbar-track {
  widows: 5px;
  background-color: #5ecc8b;
}
</style>
