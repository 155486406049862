import userDashboard from '../layouts/userdashboard.vue'

const userRoutes = {
  path: '/lawyer',
  redirect: '/lawyer/dashboard',
  component: userDashboard,
  children: [
    {
      name: 'lawyer-blawg',
      path: '/lawyer/blawg',
      component: () => import('../pages/lawyer/blawg.vue'),
      meta: {
        title: 'Blawg',
        requiresAuth: true
      }
    },
    {
      name: 'lawyer-dashboard',
      path: '/lawyer/dashboard',
      component: () => import('../pages/lawyer/dashboard.vue'),
      meta: {
        title: 'Dashboard',
        requiresAuth: true
      }
    },
    {
      name: 'lawyer-draft-an-agreement',
      path: '/lawyer/draft-an-agreement',
      component: () => import('../pages/lawyer/draft-an-agreement.vue'),
      meta: {
        title: 'Draft an Agreement',
        requiresAuth: true
      }
    },
    {
      name: 'lawyer-profile',
      path: '/lawyer/profile',
      component: () => import('../pages/lawyer/profile.vue')
    },
    {
      name: 'lawyer-shop',
      path: '/lawyer/shop',
      component: () => import('../pages/lawyer/shop.vue'),
      meta: {
        title: 'Shop',
        requiresAuth: true
      }
    },
    {
      name: 'lawyer-subscription',
      path: '/lawyer/subscription',
      component: () => import('../pages/lawyer/subscription.vue'),
      meta: {
        title: 'Subscription',
        requiresAuth: true
      }
    },
    {
      name: 'lawyer-message',
      path: '/lawyer/messages',
      component: () => import('../pages/lawyer/message.vue'),
      meta: {
        title: 'Support',
        requiresAuth: true
      }
    },
    {
      name: 'lawyer-template',
      path: '/lawyer/template',
      component: () => import('../pages/lawyer/template.vue'),
      meta: {
        title: 'Template',
        requiresAuth: true
      }
    }
  ]
}

export default userRoutes
