<template>
  <div class="bg-white fixed w-full z-10 shadow-md top-0 md:h-28 h-24">
    <div class="bg-primary-dark">
      <div
        class="
          w-10/12
          mx-auto
          py-2
          font-normal
          text-sm text-white
          flex
          items-center
          justify-end
        "
      >
        <router-link v-if="!loggedInUser" class="secondary_menu" to="/signup"
          >Register</router-link
        >
        <router-link v-if="!loggedInUser" class="secondary_menu" to="/signin"
          >Login</router-link
        >
        <router-link
          v-if="loggedInUser === 'lawyer'"
          class="secondary_menu"
          to="/lawyer/dashboard"
          >My Account</router-link
        >
        <router-link
          v-if="loggedInUser === 'user'"
          class="secondary_menu"
          to="/odr/manage-case"
          >My Account</router-link
        >
        <router-link
          v-if="loggedInUser === 'admin' || loggedInUser === 'super-admin'"
          class="secondary_menu"
          to="/admin/dashboard"
          >My Account</router-link
        >
      </div>
    </div>
    <div class="w-10/12 mx-auto py-1">
      <div class="flex items-center justify-between">
        <img
          alt="legal naija logo"
          src="../assets/Legal-Naija-Logo.jpeg"
          class="md:w-16 md:h-16 w-14 h-14"
        />
        <div
          class="cursor-pointer lg:hidden"
          @click="showMobileMenu = !showMobileMenu"
        >
          <img src="../assets/svg/menu.svg" alt="menu" class="" />
        </div>
        <div class="lg:flex items-center menus hidden">
          <a class="link" href="https://legalnaija.com/">Home</a>
          <a class="link" href="https://legalnaija.com/blawg/">Blawg</a>
          <router-link class="link" to="/">Find a Lawyer</router-link>
          <a class="link" href="https://chatbot.legalnaija.com/"
            >Chat with Lara</a
          >
          <router-link class="link" to="/shop/templates"
            >Draft An Agreement</router-link
          >
          <span
            class="link relative cursor-pointer"
            href="https://legalnaija.com/online-dispute-resolution/"
            @click="showOdrMenu = !showOdrMenu"
            >Resolve A Dispute
            <div
              v-show="showOdrMenu"
              class="
                absolute
                top-8
                py-3
                left-0
                bg-white
                shadow-md
                rounded-md
                flex flex-col
                w-full
              "
            >
              <router-link to="/odr/signup" class="link">Register</router-link>
              <router-link to="/odr/signin" class="link">Log in</router-link>
            </div>
          </span>
          <a class="link" href="https://legalnaija.com/shop/">Shop</a>
          <!-- <router-link v-if="loggedInUser !== 'admin'" class="link" to="/signin"
            >Sign In</router-link
          > -->
          <!-- <router-link
            v-if="loggedInUser !== 'admin'"
            class="link btn"
            to="/signup"
            >Sign Up</router-link
          > -->
          <!-- <router-link
            v-if="loggedInUser === 'admin'"
            class="link"
            to="/admin/dashboard"
            >My Account</router-link
          > -->
        </div>
      </div>
    </div>
    <div
      :class="`bg-white md:hidden ${
        showMobileMenu
          ? 'border-t-4 border-primary shadow-md p-4'
          : 'h-0 shadow-none p-0'
      } duration-300 w-10/12 mx-auto overflow-hidden`"
    >
      <div class="flex items-start flex-col justify-center menus-mobile">
        <a class="link" href="https://legalnaija.com/">Home</a>
        <a class="link" href="https://legalnaija.com/blawg/">Blawg</a>
        <router-link class="link" to="/">Find a Lawyer</router-link>
        <a class="link" href="https://chatbot.legalnaija.com/"
          >Chat with Lara</a
        >
        <router-link class="link" to="/shop/templates"
          >Draft An Agreement</router-link
        >
        <span class="link" @click="showOdrMobile = !showOdrMobile"
          >Resolve A Dispute</span
        >
        <div v-show="showOdrMobile" class="flex flex-col ml-3">
          <router-link
            class="link_small"
            to="/odr/signup"
            @click.native="
              showOdrMobile = false
              showMobileMenu = false
            "
            >Register</router-link
          >
          <router-link
            class="link_small"
            to="/odr/signin"
            @click.native="
              showOdrMobile = false
              showMobileMenu = false
            "
            >Sign In</router-link
          >
        </div>
        <a class="link" href="https://legalnaija.com/shop/">Shop</a>
        <!-- <router-link v-if="loggedInUser !== 'admin'" class="link" to="/signin"
          >Sign In</router-link
        >
        <router-link v-if="loggedInUser !== 'admin'" class="link btn" to="/signup"
          >Sign Up</router-link
        > -->
        <!-- <router-link
          v-if="loggedInUser === 'admin'"
          class="link"
          to="/admin/dashboard"
          >My Account</router-link
        > -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loggedInUser: null,
      showMobileMenu: false,
      showOdrMenu: false,
      showOdrMobile: false,
    }
  },
  created() {
    this.loggedInUser = this.$store.getters['auth/getLoggedInUserType']
  },
}
</script>

<style>
.menus .link {
  font-family: 'Merriweather', serif;
  @apply text-gray-700  mx-3 font-semibold hover:text-gray-500 text-base;
}

.menus .link.btn {
  @apply bg-primary-dark text-white px-3 py-1 rounded-md;
}
.menus-mobile .link {
  font-family: 'Merriweather', serif;
  @apply text-gray-700 py-3 px-2 font-semibold hover:text-gray-500 text-base;
}

.menus-mobile .link_small {
  font-family: 'Merriweather', serif;
  @apply text-gray-700 text-base py-3 px-2 font-semibold hover:text-gray-500;
}

.menus-mobile .link.btn {
  @apply bg-primary-dark text-white px-3 py-1 rounded-md mt-2;
}

/* exact link will show the primary color for only the exact matching link */
.menus-mobile a.link.router-link-exact-active {
  @apply text-primary-dark;
}

/* exact link will show the primary color for only the exact matching link */
.menus a.link.router-link-exact-active {
  @apply text-primary-dark;
}

/* exact link will show the primary color for only the exact matching link */
.menus-mobile a.link.btn.router-link-exact-active {
  @apply text-white bg-primary;
}

/* exact link will show the primary color for only the exact matching link */
.menus a.link.btn.router-link-exact-active {
  @apply text-white bg-primary;
}

.secondary_menu {
  font-family: 'Merriweather', serif;
  font-size: 12px;
  @apply text-white hover:text-white hover:text-opacity-80 font-bold mr-4 md:text-sm;
}
</style>
