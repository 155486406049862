import AdminDashboard from '../layouts/admindashboard.vue'
import AdminAuthLayout from '../layouts/adminauth.vue'

const adminRoutes = [
  {
    path: '/admin/signin',
    component: AdminAuthLayout,
    children: [
      {
        path: '/admin/signin',
        component: () => import('../pages/admin/signin.vue'),
        meta: {
          title: 'Admin Sign In'
        }
      }
    ]
  },
  {
    path: '/admin/',
    redirect: '/admin/dashboard',
    component: AdminDashboard,
    children: [
      {
        path: '/admin/dashboard',
        component: () => import('../pages/admin/dashboard.vue'),
        meta: {
          title: 'Admin Dashbaord',
          requiresAuth: true
        }
      },
      {
        path: '/admin/account-management',
        component: () => import('../pages/admin/account-management.vue'),
        meta: {
          title: 'Account Management',
          requiresAuth: true
        }
      },
      {
        path: '/admin/create-template',
        component: () => import('../pages/admin/create-template.vue'),
        meta: {
          title: 'Create Template',
          requiresAuth: true
        }
      },
      {
        path: '/admin/disputes',
        component: () => import('../pages/admin/disputes.vue'),
        meta: {
          title: 'Dispute',
          requiresAuth: true
        }
      },
      {
        name: 'admin-dispute-disputeId',
        path: '/admin/dispute/:disputeId',
        component: () => import('../pages/admin/dispute/_disputeId.vue')
      },
      {
        path: '/admin/lawyer-checker',
        component: () => import('../pages/admin/lawyer-checker.vue'),
        meta: {
          title: 'Lawyer Checker',
          requiresAuth: true
        }
      },
      {
        path: '/admin/messages',
        component: () => import('../pages/admin/messages.vue'),
        meta: {
          title: 'Messages',
          requiresAuth: true
        }
      },
      {
        path: '/admin/settings',
        component: () => import('../pages/admin/settings.vue'),
        meta: {
          title: 'Settings',
          requiresAuth: true
        }
      },
      {
        path: '/admin/subscription',
        component: () => import('../pages/admin/subscription.vue'),
        meta: {
          title: 'Subscriptions',
          requiresAuth: true
        }
      },
      {
        path: '/admin/template',
        component: () => import('../pages/admin/template.vue'),
        meta: {
          title: 'Templates',
          requiresAuth: true
        }
      },
      {
        path: '/admin/user-management',
        component: () => import('../pages/admin/user-management.vue'),
        meta: {
          title: 'Users',
          requiresAuth: true
        }
      }
    ]
  }
]

export default adminRoutes
