import AuthLayout from '../layouts/auth.vue'

const authRoutes = {
  path: '/',
  component: AuthLayout,
  children: [
    {
      path: '/signin',
      component: () => import('../pages/signin.vue'),
      meta: {
        title: 'Login'
      }
    },
    {
      path: '/signup',
      component: () => import('../pages/signup.vue'),
      meta: {
        title: 'Register a Lawyer Account'
      }
    },
    {
      path: '/forget-password',
      component: () => import('../pages/forget-password.vue'),
      meta: {
        title: 'Forget Password'
      }
    },
    {
      path: '/reset/:password_token',
      component: () => import('../pages/reset/_password_token.vue'),
      meta: {
        title: 'Reset Password'
      }
    },
    {
      path: '/verify/:email_token',
      component: () => import('../pages/verify/_email_token.vue'),
      meta: {
        title: 'Verify Email'
      }
    }
  ]
}


export default authRoutes