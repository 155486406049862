export const notify = () => {}

function logout({ $store, $router }) {
  const loggedIn = $store.getters['auth/getLoggedInUserType']
  if (loggedIn === 'admin' || loggedIn === 'super-admin') {
    $router.push('/admin/signin')
  } else if (loggedIn === 'user') {
    $router.push('/odr/signin')
  } else {
    $router.push('/signin')
  }
  $store.commit('auth/SET_TOKEN', '')
  $store.commit('auth/SET_USER', {})
}

function numberWithCommas(digits) {
  if (!digits) return 0
  const parts = digits.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

function envKeys() {
  if (
    process.env.NODE_ENV.toLowerCase() === 'dev' ||
    process.env.NODE_ENV.toLowerCase() === 'development'
  )
    return {
      paystackKey: 'pk_test_b4754f3fd66d1d1de33960d0616ee0c33bb09137',
      baseUrl: 'https://legalnaijastaging.herokuapp.com/api/',
    }
  else
    return {
      paystackKey: 'pk_live_59fe481cb79048dba1b35b13d006aa889dd7d14a',
      baseUrl: 'https://api.legalnaija.com/api/',
    }
}

export { logout, numberWithCommas, envKeys }
