import axiosInstance from "../../api/axios";
import userApi from "../../api/user";

const authModule = {
  state: () => ({
    user: {},
    token: "",
    authenticated: false,
  }),
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      if (token === "") {
        state.authenticated = false;
      } else {
        state.authenticated = true;
      }
    },
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    getProfileUpdate({ state, commit }) {
      return new Promise((resolve, reject) => {
        userApi(axiosInstance)
          .getUpdatedProfile(state.user._id, {
            populate: ["areasOfPractice.practice"],
          })
          .then((res) => {
            commit("SET_USER", res.data);
            resolve(res);
          })
          .catch((err) => {
            return reject(err)
          });
      });

      // commit('SET_USER', req.data)
    },
  },
  getters: {
    getToken: (state) => state.token,
    getLoggedInUserType: (state) =>
      Object.keys(state.user).length === 0 ? null : state.user.role,
  },
};

export default {namespaced: true, ...authModule};
