import Vue from 'vue'
import VueAxios from 'vue-axios'
import paystack from 'vue-paystack'
import VueScrollTo from "vue-scrollto"
import {
  DatePicker,
  Spin,
  Select,
  Button,
  Modal,
  Form,
  Input,
  FormModel,
  Alert,
  InputNumber,
  Table,
  Drawer,
  Tabs,
  Upload,
  Card,
  Checkbox,
  Skeleton,
  Steps,
  Icon,
  List,
  Avatar,
  notification,
  message,
   
} from 'ant-design-vue'

import './assets/css/tailwind.css'
import './assets/variables.less'
import './assets/css/main.css'

import App from './App.vue'
import store from './store'
import router from './router'
import axiosInstance from './api/axios'

Vue.config.productionTip = false

Vue.use(VueAxios, axiosInstance)
Vue.use(VueScrollTo)

Vue.use(DatePicker)
Vue.use(Spin)
Vue.use(Select)
Vue.use(Button)
Vue.use(Form)
Vue.use(Modal)
Vue.use(Input)
Vue.use(FormModel)
Vue.use(Avatar)
Vue.use(Alert)
Vue.use(InputNumber)
Vue.use(Table)
Vue.use(Drawer)
Vue.use(Tabs)
Vue.use(Upload)
Vue.use(Card)
Vue.use(Checkbox)
Vue.use(Skeleton)
Vue.use(Steps)
Vue.use(Icon)
Vue.use(List)

Vue.prototype.$notification = notification
Vue.prototype.$message = message
Vue.prototype.$confirm = Modal.confirm

Vue.component('Paystack', paystack)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
