export const lawyerRoutes = [
  {
    title: 'Dashboard',
    link: '/lawyer/dashboard',
    icon: 'appstore',
  },
  {
    title: 'Profile',
    link: '/lawyer/profile',
    icon: 'user',
  },
  {
    title: 'Draft An Agreement',
    link: '/lawyer/draft-an-agreement',
    icon: 'snippets',
  },
  {
    title: 'Plans',
    link: '/lawyer/subscription',
    icon: 'hourglass',
  },
  {
    title: 'Shop',
    link: '/lawyer/shop',
    icon: 'book',
  },
  {
    title: 'Blawg',
    link: '/lawyer/blawg',
    icon: 'hourglass',
  },
  {
    title: 'Messages',
    link: '/lawyer/messages',
    icon: 'team',
  },
]

export const userRoutes = [
  // {
  //   title: 'Dashboard',
  //   link: '/odr/dashboard',
  //   icon: 'appstore',
  // },
  {
    title: 'Profile',
    link: '/odr/profile',
    icon: 'user',
  },
  {
    title: 'Manage Case',
    link: '/odr/manage-case',
    icon: 'book',
  },
  {
    title: 'Support',
    link: '/odr/support',
    icon: 'team',
  },
  // {
  //   title: 'Resolve Dispute',
  //   link: '/odr/resolve-dispute',
  //   icon: 'team',
  // },
]

export const adminRoutes = [
  {
    title: 'Dashboard',
    link: '/admin/dashboard',
    icon: 'appstore',
  },
  {
    title: 'Practitioners Checker',
    link: '/admin/lawyer-checker',
    icon: 'team',
  },
  {
    title: 'Plans',
    link: '/admin/subscription',
    icon: 'hourglass',
  },
  {
    title: 'Agreement Template',
    link: '/admin/template',
    icon: 'snippets',
  },
  {
    title: 'Account Management',
    link: '/admin/account-management',
    icon: 'team',
  },
  {
    title: 'ODR User Management',
    link: '/admin/user-management',
    icon: 'team',
  },
  {
    title: 'Disputes',
    link: '/admin/disputes',
    icon: 'team',
  },
  {
    title: 'Messages',
    link: '/admin/messages',
    icon: 'message',
  },
  {
    title: 'Settings',
    link: '/admin/settings',
    icon: 'setting',
  },
]
