import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/auth'
import dataModule from './modules/data'
import notificationModule from './modules/notification'
import uiModule from './modules/ui'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const persistState = createPersistedState({
  key: 'legalnaija',
  paths: ['auth']
})

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: authModule,
    data: dataModule,
    notification: notificationModule,
    ui: uiModule
  },
  plugins: [persistState]
})
