import { errorResponse } from './response'

const siteDataApi = ($axios) => {
  const http = $axios
  return {
    async getAdminStats() {
      try {
        const req = await http.get(`/data/site-stats`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    // type - state,speciality,town, complaintype
    async create({ type, title }) {
      try {
        const req = await http.post(`/data/${type}`, { title })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async get({ type, orderBy = 'title', order = 'asc' }, params) {
      try {
        const req = await http.get(`/data/${type}`, {
          params: { orderBy, order, limit: 0, ...params },
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async getById({ type, typeId }) {
      try {
        const req = await http.get(`/data/${type}/${typeId}`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async update({ type, typeId, data = {} }) {
      try {
        const req = await http.put(`/data/${type}/${typeId}`, data)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async delete({ type, typeId }) {
      try {
        const req = await http.delete(`/data/${type}/${typeId}`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
  }
}

export default siteDataApi
