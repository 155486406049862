import Compressor from 'compressorjs'
import { errorResponse } from './response'

const userApi = ($axios) => {
  const http = $axios
  return {
    async getUpdatedProfile(userId, params) {
      try {
        const req = await http.get(`/users/${userId}`, { params })
        return req
      } catch (error) {
        errorResponse(error)
      }
    },
    async updateProfile({ data }) {
      delete data._id
      delete data.status
      delete data.isVerified
      delete data.createdAt
      delete data.updatedAt
      delete data.role
      delete data.currentListingPlans
      delete data.otherCertificates
      delete data.listingType
      delete data.accessedTemplateCount
      delete data.accessedTemplates
      delete data.__v

      try {
        const formData = new FormData()
        if (typeof data.avatar === 'object') {
          // eslint-disable-next-line no-new
          new Compressor(data.avatar, {
            quality: 0.75,
            success(result) {
              formData.append('avatar', result, result.name)
              delete data.avatar
            },
          })
        }
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key]
            if (Array.isArray(element) && key === 'areasOfPractice') {
              element.forEach((e, i) => {
                for (const d in e) {
                  if (Object.hasOwnProperty.call(e, d)) {
                    const element = e[d]
                    formData.append(`${key}[${i}][${d}]`, element)
                  }
                }
              })
            } else {
              formData.append(key, element)
            }
          }
        }
        const req = await http.put('/users/profile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async updateProfileSingleData({ data }) {
      try {
        const req = await http.put('/users/profile', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async sendMessage({
      senderName,
      lawyerId,
      senderEmail,
      message,
      senderPhone,
    }) {
      try {
        const req = await http.post('/users/contact', {
          senderName,
          lawyerId,
          senderEmail,
          message,
          senderPhone,
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async requestApproval() {
      try {
        const req = await http.get('/users/request-approval')
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
  }
}

export default userApi
