import axios from 'axios'
import store from '../store'
import router from '../router'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})

axiosInstance.interceptors.request.use(
  config => {
    const token = store.getters['auth/getToken']
    if (config?.params?.noAuth === true) {
      delete config.headers.Authorization
      delete config.params.noAuth
    } else if (token !== '') {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    console.log('Request error')
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  response => {
    return response?.data
  },
  error => {
    if (
      error.response.data.message.toString().toLowerCase() === 'jwt expired' ||
      error.response.data.message.toString().toLowerCase() === 'invalid token'
    ) {
      const userLoggedIn = store.getters['auth/getLoggedInUserType']
      if (userLoggedIn === 'admin') {
        router.go(`/admin/signin`)
      } else if (userLoggedIn === 'lawyer') {
        router.go(`/signin?redirect=${router.fullPath}`)
      } else if (userLoggedIn === 'user') {
        router.go(`/odr/signin?redirect=${router.fullPath}`)
      } else {
        router.go('/')
      }
    }
    console.log('Response error')
    return Promise.reject(error)
  }
)

export default axiosInstance
