<template>
  <div>
    <!-- Mobile sidebar -->
    <!-- Backdrop -->
    <transition
      v-show="sidebar"
      enter-class="transition ease-in-out duration-150"
      x-transition:enter="transition ease-in-out duration-150"
      x-transition:enter-start="opacity-0"
      x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in-out duration-150"
      leave-class="transition ease-in-out duration-150"
      x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0"
      class="fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
    ></transition>
    <aside
      class="fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-primary dark:bg-gray-800 md:hidden"
      x-show="isSideMenuOpen"
      x-transition:enter="transition ease-in-out duration-150"
      x-transition:enter-start="opacity-0 transform -translate-x-20"
      x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in-out duration-150"
      x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0 transform -translate-x-20"
      @click="closeSideMenu"
      @keydown.escape="closeSideMenu"
    >
      <sidebar-content />
    </aside>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import sidebarContent from './sidebar-content.vue'

export default {
  name: 'MobileMobile',
  components: { sidebarContent },
  computed: {
    ...mapState({
      sidebar: (state) => state.ui.sidebar,
    }),
  },
  methods: {
    closeSideMenu() {
      this.$store.commit('ui/UPDATE_SIDEBAR', false)
    },
  },
}
</script>
