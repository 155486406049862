<template>
  <!-- Desktop sidebar -->
  <aside
    id="desktop-sidebar"
    class="z-20 hidden w-64 overflow-y-auto bg-primary md:block flex-shrink-0"
  >
    <SidebarContent />
  </aside>
</template>

<script>
import SidebarContent from './sidebar-content.vue'
import { logout } from '../../utils/helpers'

export default {
  name: 'DesktopSidebar',
  components: {
    SidebarContent,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    logout() {
      logout({ $store: this.$store, $router: this.$router })
    },
  },
}
</script>

<style>
#desktop-sidebar::-webkit-scrollbar {
  width: 5px;
  border-radius: 15px;
}

#desktop-sidebar::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: #3d8854;
}

#desktop-sidebar::-webkit-scrollbar-track {
  widows: 5px;
  background-color: #5ecc8b;
}
</style>
