import Default from '../layouts/default.vue'

const mainRoutes = {
  path: '/',
  component: Default,
  children: [
    {
      path: '/',
      component: () => import('../pages/index.vue'),
      meta: {
        title: 'Search of Lawyers'
      }
    },
    {
      path: '/shop/templates',
      component: () => import('../pages/shop/templates/index.vue'),
      meta: {
        title: 'Agreement Template'
      }
    },
    {
      path: '/account/lawyer/:name/:id',
      component: () => import('../pages/_name/_id.vue'),
      meta: {
        title: 'Contact Lawyer'
      }
    },
    
  ]
}

export default mainRoutes
