const uiModule = {
  state: () => ({
    sidebar: false
  }),

  mutations: {
    UPDATE_SIDEBAR(state, data) {
      state.sidebar = data
    }
  },
  actions: {},
  getters: {}
}

export default { namespaced: true, ...uiModule }
