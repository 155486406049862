import { errorResponse } from './response'

const notificationApi = ($axios) => {
  const http = $axios
  return {
    async getAll({ params }) {
      try {
        const req = await http.get('/notifications', { params })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async getOne({ notificationId }) {
      try {
        const req = await http.get(`/notifications/${notificationId}`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async markRead({ notificationId }) {
      try {
        const req = await http.put(`/notifications/${notificationId}`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
  }
}

export default notificationApi
