<template>
  <div class="bg-gray-100 py-1">
    <router-view />
    <div class="mb-8"></div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/footer'
export default {
  components: { Footer },
}
</script>
