import axiosInstance from '../../api/axios'
import siteDataApi from '../../api/site_data'

const dataModule = {
  state: () => ({
    towns: [],
    states: [],
    specialties: []
  }),
  mutations: {
    SET_STATES(state, data) {
      state.states = data
    },
    SET_TOWNS(state, data) {
      state.towns = data
    },
    SET_SPECIALTIES(state, data) {
      state.specialties = data
    }
  },
  actions: {
    async getStates({ commit }) {
      const req = await siteDataApi(axiosInstance).get(
        { type: 'state' },
        { limit: 0 }
      )
      console.log({ req })
      commit('SET_STATES', req.data.data)
    },
    async getTowns({ commit }) {
      const req = await siteDataApi(axiosInstance).get(
        { type: 'town' },
        { limit: 0 }
      )
      commit('SET_TOWNS', req.data.data)
    },
    async getSpecialty({ commit }) {
      const req = await siteDataApi(axiosInstance).get(
        { type: 'speciality' },
        { limit: 0 }
      )
      commit('SET_SPECIALTIES', req.data.data)
    }
  },
  getters: {}
}

export default { namespaced: true, ...dataModule }
