<template>
  <header class="z-10 py-3 bg-transparent bg-white shadow-md">
    <a-drawer
      :visible="showNotification"
      :width="width > '640' ? 320 : 280"
      title="Notifications"
      @close="showNotification = false"
    >
      <div v-if="notifications.length > 0">
        <div
          v-for="notification in notifications"
          :key="notification._id"
          :class="`flex items-center`"
        >
          <div class="flex flex-col mr-3 border-b-2 border-gray-300 mb-2 pb-2">
            <span class="text-gray-500 text-xs">
              {{ timeAgo.format(new Date(notification.createdAt)) }}
            </span>
            <span v-html="notification.message"></span>
          </div>
          <span>
            <a-spin v-if="notificationLoading === notification._id"></a-spin>
            <a-icon
              v-else
              class="text-red-400 cursor-pointer"
              type="close-circle"
              @click="removeNotification(notification._id)"
            ></a-icon>
          </span>
        </div>
      </div>
      <div v-else>No notification yet</div>
    </a-drawer>
    <div
      class="
        container
        flex
        items-center
        justify-between
        h-full
        px-6
        mx-auto
        text-gray-600
      "
    >
      <!-- Mobile hamburger -->
      <div class="flex">
        <button
          class="p-1 rounded-md md:hidden focus:outline-none"
          aria-label="Menu"
          @click="toggleSideMenu"
        >
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <img
          src="../../assets/Legal-Naija-Logo.jpeg"
          class="md:w-10 md:h-10 w-8 h-8"
        />
      </div>

      <ul class="flex items-center mb-0 flex-shrink-0">
        <!-- Notifications menu -->
        <li class="relative mr-8">
          <button
            class="
              relative
              align-middle
              rounded-md
              focus:outline-none
              focus:shadow-outline-purple
            "
            aria-label="Notifications"
            aria-haspopup="true"
            @click="showNotification = !showNotification"
            @keydown.escape="showNotification = false"
          >
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
              ></path>
            </svg>
            <span
              v-show="notifications.length > 0"
              aria-hidden="true"
              class="
                absolute
                top-0
                right-0
                inline-block
                w-3
                h-3
                transform
                translate-x-1
                -translate-y-1
                bg-red-600
                border-2 border-white
                rounded-full
                dark:border-gray-800
              "
            ></span>
          </button>
        </li>
        <!-- Profile menu -->
        <li class="relative">
          <button
            class="
              align-middle
              rounded-full
              focus:shadow-outline-purple
              focus:outline-none
            "
            aria-label="Account"
            aria-haspopup="true"
            @click="toggleProfileMenu"
            @keydown.escape="closeProfileMenu"
          >
            <img
              v-if="!!!user.avatar"
              src="../../assets/svg/user_avatar_black.svg"
              class="w-8 h-8 object-cover rounded-full"
              alt="no_image"
              aria-hidden="true"
            />
            <img
              v-else
              :src="user.avatar"
              class="rounded-full w-8 h-8 object-cover"
              :alt="user.firstName"
              aria-hidden="true"
            />
          </button>
          <template v-if="isProfileMenuOpen">
            <ul
              leave-active-class="transition ease-in duration-150"
              leave-class="opacity-100"
              leave-to-class="opacity-0"
              class="
                absolute
                right-0
                w-56
                p-2
                mt-2
                space-y-2
                text-gray-600
                bg-white
                border border-gray-100
                rounded-md
                shadow-md
                dark:border-gray-700
                dark:text-gray-300
                dark:bg-gray-700
              "
              aria-label="submenu"
              @click="closeProfileMenu"
              @keydown.escape="closeProfileMenu"
            >
              <li>
                <router-link
                  class="
                    flex
                    items-center
                    space-x-2
                    text-gray-500 text-sm
                    w-full
                    hover:bg-gray-200
                    transition-colors
                    rounded
                    hover:text-gray-500
                    p-1
                  "
                  :to="menus === 'admin' ? 'settings' : 'profile'"
                >
                  <a-icon type="user" />
                  <span>Profile</span>
                </router-link>
              </li>
              <li>
                <router-link
                  class="
                    flex
                    items-center
                    space-x-2
                    text-gray-500 text-sm
                    w-full
                    hover:bg-gray-200
                    transition-colors
                    rounded
                    hover:text-gray-500
                    p-1
                  "
                  :to="menus === 'admin' ? 'messages' : 'support'"
                >
                  <a-icon type="team" />
                  <span>Support</span>
                </router-link>
              </li>
              <li>
                <span
                  class="
                    flex
                    items-center
                    space-x-2
                    text-red-500 text-sm
                    cursor-pointer
                    p-1
                  "
                  @click="logout"
                >
                  <a-icon type="logout" />
                  <span>Log out</span>
                </span>
              </li>
            </ul>
          </template>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import { logout as iLogout } from '../../utils/helpers'
import notificationApi from '../../api/notification'
import timeAgo from '../../utils/timeAgo'

export default {
  name: 'Header',
  data() {
    return {
      timeAgo,
      isNotificationsMenuOpen: false,
      isProfileMenuOpen: false,
      showNotification: false,
      notifications: [],
      notificationLoading: '',
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      sidebar: (state) => state.ui.sidebar,
    }),
    width() {
      return window.innerWidth > 0 ? window.innerWidth : screen.width
    },
    menus() {
      const presentRoute = this.$route.fullPath
      if (presentRoute.includes('admin')) return 'admin'
      if (presentRoute.includes('lawyer')) return 'lawyer'
      if (presentRoute.includes('odr')) return 'user'
      return 'admin'
    },
  },
  created() {
    this.getNotification()
  },
  methods: {
    async removeNotification(id) {
      this.notificationLoading = id
      const req = await notificationApi(this.axios).markRead({
        notificationId: id,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to remove notification',
          description: req.message,
        })
        this.notificationLoading = ''
        return
      }
      // this.$message.success('removed', '500')
      this.notificationLoading = ''
      this.getNotification()
    },
    async getNotification() {
      const req = await notificationApi(this.axios).getAll({
        params: {
          isRead: 'false',
        },
      })
      this.notifications = req.data.data
    },
    toggleSideMenu() {
      if (this.sidebar) {
        this.$store.commit('ui/UPDATE_SIDEBAR', false)
      } else {
        this.$store.commit('ui/UPDATE_SIDEBAR', true)
      }
    },
    toggleNotificationsMenu() {
      this.isProfileMenuOpen = false
      this.isNotificationsMenuOpen = !this.isNotificationsMenuOpen
    },
    closeNotificationsMenu() {
      this.isNotificationsMenuOpen = false
    },
    closeProfileMenu() {
      this.isProfileMenuOpen = false
    },
    toggleProfileMenu() {
      this.isNotificationsMenuOpen = false
      this.isProfileMenuOpen = !this.isProfileMenuOpen
    },
    logout() {
      iLogout({ $store: this.$store, $router: this.$router })
    },
  },
}
</script>
