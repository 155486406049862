import userDashboard from '../layouts/userdashboard.vue'

const odrUserRoutes = {
  path: '/odr',
  component: userDashboard,
  children: [
    {
      name: 'odr-support',
      path: '/odr/support',
      component: () => import('../pages/odr/support.vue'),
      meta: {
        title: 'Support',
        requiresAuh: true
      }
    },
    {
      name: 'odr-dispute-disputeId',
      path: '/odr/dispute/:disputeId?',
      component: () => import('../pages/odr/dispute/_disputeId.vue'),
      meta: {
        title: 'Dispute',
        requiresAuh: true
      }
    },
    {
      name: 'odr-manage-case',
      path: '/odr/manage-case',
      component: () => import('../pages/odr/manage-case.vue'),
      meta: {
        title: 'Manage Case',
        requiresAuh: true
      }
    },
    {
      name: 'odr-profile',
      path: '/odr/profile',
      component: () => import('../pages/odr/profile.vue'),
      meta: {
        title: 'Profile',
        requiresAuh: true
      }
    },
    {
      name: 'odr-resolve-dispute',
      path: '/odr/resolve-dispute',
      component: () => import('../pages/odr/resolve-dispute.vue'),
      meta: {
        title: 'Resolve Dispute',
        requiresAuh: true
      }
    }
  ]
}

export default odrUserRoutes
